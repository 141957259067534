<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
        <b-row>
          <b-col lg="5" md="5" sm="5" xs="5">
            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="formData.org_id"
                  :options="componentOrgList"
                  @input="getTrainerEvaluationData(formData.org_id)"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row v-for="(detail, index) in formData.details" :key="index" class="mt-3">
            <b-col lg="5" md="5" sm="5" xs="5">
              <ValidationProvider name="Trainer Evaluation Name (En)" vid="trainer_evaluation_name" rules="required">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="trainer_evaluation_name"
                  slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                  {{$t('elearning_config.evaluation_name_en')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                  id="trainer_evaluation_name"
                  v-model="detail.trainer_evaluation_name"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="5" md="5" sm="5" xs="5">
                <ValidationProvider name="Trainer Evaluation Name (Bn)" vid="trainer_evaluation_name_bn" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="trainer_evaluation_name_bn"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('elearning_config.evaluation_name_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    id="trainer_evaluation_name_bn"
                    v-model="detail.trainer_evaluation_name_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xl="2" lg="2" sm="2" style="margin-top: 36px;">
              <b-button v-show="index == formData.details.length-1" variant="success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>{{ $t('globalTrans.add') }}</b-button>
              <b-button v-show="index || ( !index && formData.details.length > 1)" variant="danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
            </b-col>
        </b-row>
        <b-row>
        <div class="col text-right mt-3">
          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
        </div>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationStore, trainerEvaluationShowData } from '../../api/routes'

export default {
  props: ['id', 'org_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.formData.org_id = this.org_id
      this.getTrainerEvaluationData(this.org_id)
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_id: 0,
        details: [
          {
            trainer_evaluation_name: '',
            trainer_evaluation_name_bn: '',
            status: 1
          }
        ]
      }
    }
  },
  computed: {
    componentOrgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  watch: {
  },
  methods: {
    addItem () {
      const obj = {
        // org_id: 0,
        trainer_evaluation_name: '',
        trainer_evaluation_name_bn: '',
        status: 1
      }
        const key1 = parseInt(this.formData.details.length - 1)
        const item = this.formData.details[key1]
        let isEmpty = true
        Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
        })
        if (isEmpty === true) {
          this.formData.details.push(obj)
        }
    },
    remove (key) {
      this.formData.details.splice(key, 1)
    },
    async getTrainerEvaluationData (orgId) {
      this.loading = true
      const params = {
        org_id: orgId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationShowData, params)
      if (result.success) {
        this.formData.details = result.data
      } else {
        this.formData.details = [{}]
      }
      this.loading = false
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      // if (this.id) {
      //   result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainerEvaluationUpdate}/${this.id}`, this.formData[0])
      // } else {
      //   result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerEvaluationStore, this.formData)
      // }

      result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerEvaluationStore, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
