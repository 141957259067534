<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('globalTrans.organization') }}</b-th>
                                <b-td style="width:30%">{{ getOrgnizationName(formData.org_id) }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr class="bg-primary">
                                <th scope="col" style="width: 10%;" class="text-center"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                <th scope="col" style="width: 40%;"  class="text-center"><div>{{ $t('elearning_config.evaluation_name') }}</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="formData.details && formData.details.length > 0">
                                <tr v-for="(item, index) in formData.details" :key="index">
                                    <td  class="text-center">{{ index+1 }}</td>
                                    <td  class="text-left">{{ currentLocale === 'bn' ?item.trainer_evaluation_name_bn : item.trainer_evaluation_name  }}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                </tr>
                            </template>
                        </tbody>
                  </table>
                  </b-col>
              </b-row>
              </b-overlay>
            </template>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationShowData } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item', 'org_id'],
    created () {
        this.formData.org_id = this.org_id
        this.getTrainerEvaluationData(this.org_id)
    },
    data () {
        return {
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            formData: {
            org_id: 0,
            details: [
              {
                trainer_evaluation_name: '',
                trainer_evaluation_name_bn: '',
                status: 1
              }
        ]
      }
        }
    },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
    },
  methods: {
    // getdetailsData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // }
    async getTrainerEvaluationData (orgId) {
      this.loading = true
      const params = {
        org_id: orgId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationShowData, params)
      if (result.success) {
        this.formData.details = result.data
      } else {
        this.formData.details = [{}]
      }
      this.loading = false
    },
    getOrgnizationName (orgId) {
      const cateObj = this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1).find(item => parseInt(item.value) === parseInt(orgId))
      if (cateObj !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return cateObj.text_bn
        } else {
          return cateObj.text_en
        }
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
