<template>
    <b-container fluid>
         <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('elearning_config.trainer_evaluation')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col xs="6" sm="12" md="6">
                        <ValidationProvider name="Organization">
                            <b-form-group
                                label-for="org_id"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.organization')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.org_id"
                                :options="orgList"
                                id="org_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                        <b-button size="sm" class="mt-3" variant="primary" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_config.trainer_evaluation_list')}}</h4>
            </template>
            <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                    <template v-slot:cell(serial)="data">
                                    {{ $n(data.item.serial + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                    <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                                        <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                                        <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                                        </b-button>
                                        <a href="javascript:" variant=" iq-bg-success mr-1" class="btn action-btn status mr-1 btn-sm" title="View Details" v-b-modal.modal-5 hide-footer @click="details(data.item)"><i class="ri-eye-line m-0"></i></a>
                                    </template>
                                </b-table>
                            </slot>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                v-model="pagination.currentPage"
                                :perPage="search.limit"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :org_id="orgId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_config.trainer_evaluation') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="editItemId" :org_id="orgId" :key="editItemId"></Details>
        </b-modal>
    </b-container>
</template>
<script>

import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationList, trainerEvaluationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Store from '@/store'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            sortBy: '',
            sortDirection: '',
            sortDesc: '',
            editItemId: 0,
            viewItemId: 0,
            orgId: 0,
            search: {
                org_id: 0,
                limit: 20
            },
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'globalTrans.org_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
                { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
                { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '15%' } }
            ],
            actions: {
                edit: true,
                toogle: true,
                details: true
            }
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'serial' },
                { key: 'org_name_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'serial' },
                { key: 'org_name' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        pageOptions () {
            return this.$store.state.commonObj.pageOptions
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.limit': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadData()
            }
        }
    },
    methods: {
        edit (item) {
            this.orgId = item.org_id
            this.editItemId = item.id
        },
        details (item) {
            this.viewItemId = item.id
            this.orgId = item.org_id
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.statusChangeMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatus(trainingElearningServiceBaseUrl, trainerEvaluationToggleStatus, item)
            }
          })
        },
        changeStatus (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.org_id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          })
        },
        // remove (item) {
        //     this.changeStatus(12, trainingElearningServiceBaseUrl, trainerEvaluationToggleStatus, item)
        // },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data, this.search.limit)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
            const orgList = this.$store.state.CommonService.commonObj.componentOrgList
            let tmpData = {}
            const listData = data.map((item, index) => {
                tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
                const orgData = {}
                if (typeof tmpData !== 'undefined') {
                    orgData.org_name = tmpData.text_en
                    orgData.org_name_bn = tmpData.text_bn
                } else {
                    orgData.org_name = ''
                    orgData.org_name_bn = ''
                }
                return Object.assign({}, item, { serial: index + 0 }, orgData)
            })
            return listData
        }
    }
}
</script>
